import react from "react";
import { Col, Container, Row } from "react-bootstrap";
import LeftIconMediaPlain from "./LefticonMediaPlain";

import app from "../images/appscreen.png"; 
import check from "../images/check.png"; 
import ios from "../images/app-store.png"; 
import play from "../images/googlepay.png"; 

const TwoColAppSection = ({ Info = null}) => {
  const defaultInfo = [
    {
      icon: check, 
      description: "Easy and powerful tools for content creator. Podcasting never get easier",
    },
    {
      icon: check, 
      description:
        "Complete guide to start your podcast channel. Reach your audience effectively.",
    },
    {
      icon: check, 
      description: "Fully remarkable assistant from our customer support. 24/7 online.",
    },
  ]; 

  if (!Info) Info = defaultInfo;

  return (
    <>
      <div className="app_wrap">
        <Container fluid>
          <Row>
            <Col  lg="6" className="text-center">
              <img src={app} className="mob__img" alt="image " />
            </Col>
            <Col   lg="6">
                <h4 className="section__title text-left mt-5">
                Get all access in your hands now
                </h4>
              <Row className="mb-5">
                {Info.map((info, i) => (
                  <Col   lg="12" key={i}>
                    <LeftIconMediaPlain
                      icon={info.icon} 
                      description={info.description}
                    />
                  </Col>
                ))}
              </Row>
              <a href="">
              <img src={ios} alt="image " />
              </a> &nbsp;
              <a href="">
              <img src={play} alt="image " />
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default TwoColAppSection;
