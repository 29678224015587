import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import ServiceImage from "../images/about.png";

const ServiceList = ({ Info = null, SectionTitle }) => {
  const defaultInfo = [
    {
      title: "Transportation Service",
      description:
        "Experience the hassle-free Hudson airport journey with Hudson airport taxi. Whether you are looking for airport car service.",

      link: "",
    },
    {
      title: "Quick Booking via website or Mobile App",
      description:
        "Experience the hassle-free Hudson airport journey with Hudson airport taxi. Whether you are looking for airport car service.",
      link: "",
    },

    {
      title: "Our Fleets",
      description:
        "Experience the hassle-free Hudson airport journey with Hudson airport taxi. Whether you are looking for airport car service.",
      link: "",
    },
    {
      title: "Call us for your quick ride in Boston Areas",
      description:
        "Experience the hassle-free Hudson airport journey with Hudson airport taxi. Whether you are looking for airport car service.",
      link: "",
    },
  ];

  if (!Info) Info = defaultInfo;
  return (
    <>
      <div className="section__gap services  pt-0">
        <Container fluid className="full__width">
          <Row>
            <Col lg="6">
              <img src={ServiceImage} alt="img" />
            </Col>
            <Col lg="6">
              <div className="text-left">
                {SectionTitle && (
                  <h4 className="text-left small__title">{SectionTitle}</h4>
                )}
                <h2 className="section__title text-left">
                  The Luxurious <span> Service </span>
                </h2>
                <p>
                  Hudson Airport taxi provides 24/7 taxi service to/from Boston
                  Logan Airport to Hudson, MA. or Hudson, MA to Boston Logan
                  Airport and other twin city area
                </p>
              </div>
            </Col>
          </Row>
          <section className="service__lists">
            <Row>
              {Info.map((info, i) => (
                <Col lg="6" key={i}>
                  <h4>{info.title}</h4>
                  <p>
                    {info.description} <a href={info.link}>See more</a>
                  </p>
                </Col>
              ))}
            </Row>
          </section>
        </Container>
      </div>
    </>
  );
};
export default ServiceList;
