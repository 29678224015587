import React from "react";

import Nav from "../components/NavComp";
import Banner from "../components/MainBannerComp";
import ThreeColWithImage from "../components/ThreeColWithImage";
import TwoColAppSection from "../components/TwoColAppSection";
import ReviewSlider from "../components/ReviewSlider";
import FooterComp from "../components/FooterComp"; 
import ServiceList from "../components/ServiceList";
import FloatingContact from "../components/FloatingContact";
const Index = () => {
  return (
    <>
      <Nav
        title="Hudson Airport Taxi Cab | Boston Logan Taxi Service"
        desc="Hudson Airport taxi Cab provides 24/7 taxi service to and from Boston Logan Airport to Hudson at a very affordable and lowest price.Call (978) 513-3000"
      />
      <Banner />
      <ThreeColWithImage SectionTitle="Our Fleet" /> 
      <ServiceList SectionTitle="Hudson airport Taxi" />
      <ReviewSlider SectionTitle="What Our Clients say" />
      <TwoColAppSection /> 
      <FloatingContact />
      <FooterComp />
    </>
  );
};

export default Index;
