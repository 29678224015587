import React from "react";
import Nav from "../components/NavComp";
import InnerBanner from "../components/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../images/about-banner.png";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import FooterComp from "../components/FooterComp";
import Button from "react-bootstrap/Button";
import ReCAPTCHA from "react-google-recaptcha";

const Fleet = () => {
  return (
    <>
      <Nav
        title="Contact us Acton MA Airport Taxi to Boston Logan Airport"
        desc="If you need a Logan airport or Boston Logan airport taxi, call us. We promise to help you reach your destination in time with no complications Contact us"
      />
      <InnerBanner BannerImage={Banner} />
      <div className="section__gap contact__page">
        <Container fluid>
          <Row>
            <Col lg="12">
              <h2 className=" text-center centered section__title hav__icon mb-5">
                Contact Us
              </h2>
            </Col>
            <Col lg="7">
              <h4 className="mb-4  md__title ">
                Contact us for Hudson Airport Taxi
              </h4>

              <p>Hudson Airport Taxi</p>
              <p>
                <a href="tel:978) 513-3000">(978) 513-3000</a>
              </p>
              <p>
                <a href="mailto:info@hudsonairporttaxi.com">
                  info@hudsonairporttaxi.com
                </a>
              </p>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d94153.27948325188!2d-71.5119194897808!3d42.48538732325967!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e39130277677cf%3A0xa604d08b2a8d760!2sActon%2C%20MA%2C%20USA!5e0!3m2!1sen!2snp!4v1657194047947!5m2!1sen!2snp"
                width="100%"
                height="350"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Col>

            <Col lg="5">
              <Form className="contact-form">
                <h3>Send your Queries</h3>
                <Form.Group className="mb-3">
                  <Form.Control type="text" placeholder="Full Name" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control type="text" placeholder="Phone Number" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control type="text" placeholder="Email Address" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control as="textarea" rows="4" placeholder="Message" />
                </Form.Group>
                <ReCAPTCHA sitekey="111" />,
                <Button className="btn-block btn__main ">Submit</Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>

      <FooterComp />
    </>
  );
};

export default Fleet;
