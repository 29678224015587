import React from "react";
import Nav from "../components/NavComp";
import InnerBanner from "../components/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../images/about-banner.png";
import FooterComp from "../components/FooterComp";
import ListGroup from "react-bootstrap/ListGroup";
const ServiceAreaDetails = (props) => {
  return (
    <>
      <Nav title={props.metatitle} desc={props.metadesc} />
      <InnerBanner BannerImage={Banner} />
      <div className="section__gap ">
        <Container fluid>
          <h2 className=" text-center section__title centered hav__icon mb-5">
            {props.title}
          </h2>
          <Row>
            <Col lg="12">
              {props.titlesm && (
                <h4 class="mb-2 mt-4  ">
                  <strong>{props.titlesm}</strong>
                </h4>
              )}
              <p>{props.desc}</p>
              {props.title1 && (
                <h4 class="mb-2 mt-4  md__title ">{props.title1}</h4>
              )}
              {props.desc1 && <p>{props.desc1}</p>}
              {props.title2 && (
                <h4 class="mb-2 mt-4  md__title ">{props.title2}</h4>
              )}
              {props.desc2 && <p>{props.desc2}</p>}

              {props.l1 && (
                <ListGroup className="diamond-list">
                  {props.l1 && <ListGroup.Item>{props.l1}</ListGroup.Item>}
                  {props.l2 && <ListGroup.Item>{props.l2}</ListGroup.Item>}
                  {props.l3 && <ListGroup.Item>{props.l3}</ListGroup.Item>}
                  {props.l4 && <ListGroup.Item>{props.l4}</ListGroup.Item>}
                  {props.l5 && <ListGroup.Item>{props.l5}</ListGroup.Item>}
                  {props.l6 && <ListGroup.Item>{props.l6}</ListGroup.Item>}
                  {props.l7 && <ListGroup.Item>{props.l7}</ListGroup.Item>}
                  <br /> 
                </ListGroup>
              )}

              {props.title3 && (
                <h4 class="mb-2 mt-4  md__title ">{props.title3}</h4>
              )}
              {props.desc3 && <p>{props.desc3}</p>}
              {props.title4 && (
                <h4 class="mb-2 mt-4  md__title ">{props.title4}</h4>
              )}
              {props.desc4 && <p>{props.desc4}</p>}
              {props.title5 && (
                <h4 class="mb-2 mt-4  md__title ">{props.title5}</h4>
              )}
              {props.desc6 && <p>{props.desc5}</p>}
              {props.title6 && (
                <h4 class="mb-2 mt-4  md__title ">{props.title6}</h4>
              )}
              {props.desc6 && <p>{props.desc6}</p>}
              {props.title7 && (
                <h4 class="mb-2 mt-4  md__title ">{props.title7}</h4>
              )}
              {props.desc7 && <p>{props.desc7}</p>}
              {props.title8 && (
                <h4 class="mb-2 mt-4  md__title ">{props.title8}</h4>
              )}
              {props.desc8 && <p>{props.desc8}</p>}
              {props.title9 && (
                <h4 class="mb-2 mt-4  md__title ">{props.title9}</h4>
              )}
              {props.desc9 && <p>{props.desc9}</p>}
              {props.title10 && (
                <h4 class="mb-2 mt-4  md__title ">{props.title10}</h4>
              )}
              {props.desc10 && <p>{props.desc10}</p>}
              {props.boldtitle1 && (
                <h4 class="mb-2 mt-5 small__title ">
                  <strong>{props.boldtitle1}</strong>
                </h4>
              )}
              {props.boldtitle2 && (
                <h4 class="small__title ">
                  <strong>{props.boldtitle2}</strong>
                </h4>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <FooterComp />
    </>
  );
};
export default ServiceAreaDetails;
