import React from "react";
import Nav from "../components/NavComp";
import InnerBanner from "../components/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../images/about-banner.png";
import FooterComp from "../components/FooterComp";

import ListGroup from "react-bootstrap/ListGroup";
const Service = () => {
  return (
    <>
      <Nav
        title="Ground Transportation Solutions for Airport & Station Transfers"
        desc="Hudson Taxi 978 513 3000 provides 24/7 Reliable, Low-Cost SUV, Town Car, Passenger Van, Taxi service from and to Airport to Hudson MN."
      />
      <InnerBanner BannerImage={Banner} />
      <div className="section__gap ">
        <Container fluid>
          <h2 className=" text-center section__title centered hav__icon mb-5">
            Hudson Airport Taxi Services
          </h2>
          <Row>
            <Col lg="12">
              <h4 className="mb-4   small__title">
                <strong>
                  24/7 taxi service to/from Boston Logan Airport to Hudson
                </strong>
              </h4>

              <h4 className="mb-4   md__title">
                <strong>Airport Transport Service</strong>
              </h4>
              <p>
                We specialize in providing an airport taxi service to/from
                Boston Logan Airport to Hudson, MA. or Hudson, MA to Boston
                Logan Airport.
              </p>

              <h4 className="mb-4   md__title">
                <strong>Corporate Transfer Service</strong>
              </h4>
              <p>
                Our immaculate range of luxury and a prestigious car is
                available for any business travel, VIP airport transfer,
                corporate roadshows and business meeting in Hudson and other
                areas such as Sudbury MA, Wayland MA, Weston Ma, Maynard MA,
                Natick MA, Boston MA.
              </p>

              <h4 className="mb-4   md__title">
                <strong>Station Transfer Service</strong>
              </h4>
              <p>
                We specialized in providing station transfer service to and from
                all major Hudson train station at very low rates. Our chauffeurs
                will take you to your desired destination at a very relax and
                comfortable way in our luxurious vehicles. We provide meet and
                greet service as well upon request.
              </p>
              <h4 className="mb-4   md__title">
                <strong>Seaport Transfer Service</strong>
              </h4>
              <p>
                We provide reliable and convenient to and from all major ports
                and seaport transfer throughout the Hudson. Our chauffeurs are
                always ready to help you with your luggage and will make sure
                you and your belongings are reached your destination safely and
                on time.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <FooterComp />
    </>
  );
};
export default Service;
