import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const FloatingContact = () => {
  return (
    <>
      <div className="floating__sidebar_bg">
        <Container fluid>
          <Row>
            <Col lg="8">
              <h4>Facilities</h4>
              <h2>Traveling to Boston or Logan from your location?</h2>
              <a href="" className="btn btn__main">
                Book Now
              </a>
            </Col>
            <Col lg="4">
              <div className="float__sidebar">
                <p>
                  <i className="fa-solid fa-phone"></i> GIVE US A CALL FOR INSTANT
                  BOOKING
                </p>
                <p>
                  <i className="fa-solid fa-dollar-sign"></i> WE MAKE SURE YOU NEVER
                  PAY TOO MUCH
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default FloatingContact;
