import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import logo from "../images/logo.png";

const FooterComp = () => {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <>
      <footer>
        <Container fluid>
          <Row>
            <Col lg="3">
              <img src={logo} />
              <p>
                Hudson Airport Taxi is a Private Hire Service. We specialize in
                "Airport and seaport transfers"
              </p>
              <div className="social-icons">
                <a href="#!">
                  <i className="fa-brands fa-facebook-f"></i>
                </a>
                <a href="#!">
                  <i className="fa-brands fa-twitter"></i>
                </a>
                <a href="#!">
                  <i className="fa-brands fa-instagram"></i>
                </a>
              </div>
            </Col>
            <Col lg="2">
              <h4>Useful Links</h4>
              <ListGroup>
           
                <ListGroup.Item>
                  <a href="/services">Service</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/fleets">Fleet</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/contact">Contact</a>
                </ListGroup.Item>
              </ListGroup>
            </Col>

            <Col lg="4">
              <h4>Location We Serve</h4>
              <ListGroup className="list__count__2">
              <ListGroup.Item>
                  <a href="/hudson">Hudson</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/marlborough">Marlborough</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/boston">Boston</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/framingham">Framingham</a>
                </ListGroup.Item>
              
               
                <ListGroup.Item>
                  <a href="/maynard">Maynard</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/natick">Natick</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/northborough">Northborough</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/sudbury">Sudbury</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/wayland">Wayland</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/westborough">Westborough</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/weston">Weston</a>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col lg="3">
              <h4>Our Office</h4>
              <p>
                <strong>Office: </strong>
                Concord Airport Taxi & Car Services 300 Baker Avenue, Concord,
                MA 01742
              </p>
              <p>
                <strong>Phone: </strong>
                <a href="tel:1(978) 513-3000">1(978) 513-3000</a>
              </p>
              <p>
                <strong>Email: </strong>
                <a href="mailto:info@hudsonairporttaxi.com">
                  info@hudsonairporttaxi.com
                </a>
              </p>
            </Col>
          </Row>
          <hr />
          <div className="copyright">
            <p>Copyright {year} All Rights Reserved. Hudson Airport Taxi.</p>
            <p>
              <a href="/terms">Terms & Conditions</a>

              <a href="/privacy-policy">Privacy policy</a>
            </p>
          </div>
        </Container>
      </footer>
    </>
  );
};
export default FooterComp;
