import React from "react";

import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import review from "../images/reviweer.png";

const ReviewSlider = ({ Info = null, SectionTitle }) => {
  const settings = {
   
    slidesToShow: 4,
    slidesToScroll: 1,
  };
  const defaultInfo = [
    {
      icon: review,
      title: "Hannah Schmitt",
      date: "May 8 2020",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus ",
    },
    {
      icon: review,
      title: "Mr. Man",
      date: "May 8 2022",
      description:
        "Labore et dolore magna aliqua. Ut enim corehenderit in cupidatat non proident, sun. ",
    },
    {
      icon: review,
      title: "John Doe",
      date: "July 8 2022",
      description:
        "Serspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.",
    },
  
  ];

  if (!Info) Info = defaultInfo;
  return (
    <>
      <div className="section__gap homepage-review  pb-5">
        <Container fluid>
          <Row>
            <Col  lg="12">
              <h2 className="section__title text-left">
               {SectionTitle}
              </h2>
              <p className="text-left">
              These are things that clients who have used our features say.
              </p>
              {/* <Slider {...settings}>
                
              </Slider> */}
            </Col>
       
          </Row>
          <Row>
          {Info.map((info, i) => (
                  <Col className="reviewitem" lg="4" key={i}>
                    <div className="reviewer-info">
                      <img src={info.icon} alt="" />
                      <h5>
                        {info.title}
                       
                      </h5>
                    </div>
                    <p className="text-left">{info.description}</p>
                    <small>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                        </small>
                  </Col>
                ))}
          </Row>
        </Container>
      </div>
    </>
  );
};
export default ReviewSlider;
