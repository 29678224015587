import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Slider from "react-slick";

import paxIcon from "../images/pax-icon.png";
import lugIcon from "../images/lug-icon.png";
import Img1 from "../images/suv.png";
import Img2 from "../images/sedan.png";
import Img3 from "../images/compact.png";

const ThreeColWithImage = ({ Data = null, SectionTitle }) => {
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  const defaultData = [
    {
      title: "4-Passenger SUV",
      description:
        "Compact Sedan for up-to 2-Passenger with 2 carry on Luggages | Economic & Spacious car.",
      img: Img1,
      rating: "5.0",
      price: "$250",
      pax: "4",
      lug: "4",
      url: "/our-fleets",
    },
    {
      title: "10-Passenger VAN",
      description:
        "Go 10- Passenger Van | Capacity :Maximum 10 passenger & 10 Bags",
      img: Img2,
      rating: "5.0",
      price: "$460",
      pax: "10",
      lug: "10",
      url: "/our-fleets",
    },
    {
      title: "6-PAX MINIVAN",
      description:
        "Go 4 - Passenger Minivan | Maximum 10 Bags Capacity Premium Class Vehicle",
      img: Img3,
      rating: "4.5",
      price: "$670",
      pax: "4",
      lug: "10",
      url: "/our-fleets",
    },
    {
      title: "6-PAX MINIVAN",
      description:
        "Go 4 - Passenger Minivan | Maximum 10 Bags Capacity Premium Class Vehicle",
      img: Img3,
      rating: "4.5",
      price: "$670",
      pax: "4",
      lug: "10",
      url: "/our-fleets",
    },
  ];

  if (!Data) Data = defaultData;
  return (
    <div className="section__gap threecolwithimage__wrapper ">
      <Container fluid>
        <div className="text-center">
          {SectionTitle && <h4 className="small__title">{SectionTitle}</h4>}
          <h2 className="section__title">
            <span> Trending </span> cars great and <br /> fast this week
          </h2>
          <p>
            Our quality range of Boston Airport fleets includes Premium Sedan, 2
            to 3 premium sedan, 6 to 7 passenger minivan, 4 passenger minivan, 3
            passenger SUV, 6 passenger Minivan and 10 to 12 passenger group van.
          </p>
        </div>
        <div className="text-center">
          <Tabs defaultActiveKey="recommended">
            <Tab eventKey="recommended" title="Recommended">
              <Row>
                {Data.map((info, i) => (
                  <div className="threecolwithimage__unit" lg="3" key={i}>
                    <div className="rating__wrap">
                      <i className="fa fa-star"></i> {info.rating}
                    </div>
                    <div>
                      <img src={info.img} alt={info.title || ""} />
                      <h4>{info.title || ""}</h4>
                      <p className="description">{info.description || ""}</p>
                      <div className="fleet_stat">
                        <span><img src={paxIcon} />{info.pax}</span>
                        <span><img src={lugIcon} /> {info.lug}</span>
                        </div>
                      <div className="price-box"> 
                        <a href="/fleets" className="btn__main">View More</a>
                      </div>
                    </div>
                  </div>
                ))}
              </Row>
            </Tab>
            <Tab eventKey="trending" title="Trending">
              <Row>
                {Data.map((info, i) => (
                  <div className="threecolwithimage__unit" lg="3" key={i}>
                    <div className="rating__wrap">
                      <i className="fa fa-star"></i> {info.rating}
                    </div>
                    <div>
                      <img src={info.img} alt={info.title || ""} />
                      <h4>{info.title || ""}</h4>
                      <p className="description">{info.description || ""}</p>
                      <div className="price-box">
                        <h5>{info.price}</h5>
                        <Button className="btn__main">View More</Button>
                      </div>
                    </div>
                  </div>
                ))}
              </Row>
            </Tab>
            <Tab eventKey="frequentlyvisited" title="Frequently Visited">
              <Row>
                {Data.map((info, i) => (
                  <div className="threecolwithimage__unit" lg="3" key={i}>
                    <div className="rating__wrap">
                      <i className="fa fa-star"></i> {info.rating}
                    </div>
                    <div>
                      <img src={info.img} alt={info.title || ""} />
                      <h4>{info.title || ""}</h4>
                      <p className="description">{info.description || ""}</p>
                      <div className="price-box">
                        <h5>{info.price}</h5>
                        <Button className="btn__main">View More</Button>
                      </div>
                    </div>
                  </div>
                ))}
              </Row>
            </Tab>
          </Tabs>
        </div>
      </Container>
    </div>
  );
};

export default ThreeColWithImage;
