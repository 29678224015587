import React, { Component } from "react"; 
import Banner from "../images/banner.png";

import Quote from "../components/QuoteComp"

const MainBannerComp = () => {
  return (
    <div className="hp__banner">
      <Quote />
      <img src={Banner} alt="banner" />
    
      <div className="banner__text">
        <div className="highlighted">
          <span>Trusted </span> The best hotel booking site 2021
        </div>
        <h1>Reliable airport Service In Town</h1>
        <p>
          Experience the hassle-free Hudson airport journey with Hudson airport
          taxi. Whether you are looking for airport car service.
        </p>
      </div>
    </div>
  );
};

export default MainBannerComp;
