import React from "react";
import Nav from "../components/NavComp";
import InnerBanner from "../components/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../images/about-banner.png";
import FooterComp from "../components/FooterComp";

const Terms = () => {
  return (
    <>
      <Nav
        title="Hudson Airport Transfer | Taxi Service"
        desc="Hudson Airport taxi provides 24/7 taxi service to/from Boston Logan Airport to Hudson  at a very affordable and lowest price."
      />
      <InnerBanner BannerImage={Banner} />
      <div className="section__gap contact__page">
        <Container fluid>
          <Row>
            <Col lg="12">
              <h2 className=" text-center centered section__title hav__icon mb-5">
                Terms & Conditions
              </h2>
              <p>
                We accept all types of Credit Cards & PayPal. Hudson Airport
                Taxi & Car Services (DBA) is incorporated by Logan Airport Car,
                Inc.
              </p>
              <p>
                "LOGAN AIRPORT CAR, INC" will appear on the Credit Card /PayPal
                billing statement.
              </p>
              <h4 class="mb-2 mt-4  md__title ">Service Hours</h4>
              <p>
                We provide 24/7 Airport Transportation - except State of
                Emergency.
              </p>
              <h4 class="mb-2 mt-4  md__title "> Payment Options</h4>
              <p>
                PayPal or Credit Card. An option of payment must be required to
                complete online booking. All PayPal payment goes to under
                Corporate email to :(loganairportcarservice@gmail.com).
              </p>
              <h4 class="mb-2 mt-4  md__title ">Luggage Restriction</h4>
              <p>
                There are maximum luggage restrictions depending on the vehicle
                Capacity. Please check your vehicle luggage capacity from the
                fleet/Fare Quote page. Any additional Luggage may cost each
                $3.00 – Please Specify the Luggage amount while booking.
              </p>
              <h4 class="mb-2 mt-4  md__title ">Change</h4>
              <p>
                Change/Modify/Cancellation is free of cost before 4 (Four) hours
                of actually scheduled pickup. Please simply email
                info@actonairporttaxi.com for the change request.
              </p>
              <h4 class="mb-2 mt-4  md__title ">Refund</h4>
              <p>
                Any refund Paid by PayPal/Credit Card will be processed within
                24 (Twenty Four) Hours.
              </p>
              <h4 class="mb-2 mt-4  md__title ">No-Show</h4>
              <p>
                In case of passenger no-show. A no-show (full fare) will
                occurred, no refund will be allowed. Passenger must contact us –
                or- email for rescheduling/change/cancel an existing reservation
                4 (Four) Hours before scheduled pickup.
              </p>
              <h4 class="mb-2 mt-4  md__title ">Cancellation</h4>
              <p>
                Cancellation before 4 (Four) hours - will be refunded in full.
                Less than 4 (Four) hours a refund will not be allowed.
              </p>
              <h4 class="mb-2 mt-4  md__title ">Reservation</h4>
              <p>
                (DAY) From 8:00 A.M - 8:00 P.M. Online Reservation Required at
                least 4 (Four) hours advance to schedule a Car. (NIGHT) From
                8:00 PM - 8:00 AM required minimum 8 (Eight) hours for online
                reservation. Need Urgent Booking, Please Call us (978) 513-3000.
              </p>
              <h4 class="mb-2 mt-4  md__title ">Tolls & Fees</h4>
              <p>
                All Tolls & Charges are Included in fare as per Massachusetts
                Transportation Authority.
              </p>
              <h4 class="mb-2 mt-4  md__title ">State Emergency</h4>
              <p>
                In case of State Emergency or snow Storms or heavy snowfall, we
                might have to cancel the service, we will contact passenger
                before cancellation. Canceled for Natural Causes, Change/Refund
                can be requested/Issued.
              </p>
              <h4 class="mb-2 mt-4  md__title ">To Airport-Pickup</h4>
              <p>
                Waiting Time: All Pick-up to the Airport - Driver will wait up
                to 15 (Fifteen) minutes. Please contact driver, if you need more
                time. Please contact driver, if you need more time or No-Show
                may occurred.
              </p>
              <h4 class="mb-2 mt-4  md__title ">From Airport Pick-Up</h4>
              <p>
                Waiting Time: All Pickup-from the airport - Driver will wait up
                to 1 (One) hour from the actual flight arrival/landing time. If
                you need more than 1 (ONE) hours - Please contact driver, if you
                need more time or No-Show may occurred.
              </p>
              <h4 class="small__title ">
              Have Any Question? Please Call : (978) 513-3000.
              </h4>
            </Col>
          </Row>
        </Container>
      </div>

      <FooterComp />
    </>
  );
};

export default Terms;
